@import "../../constants";

:root {
  --screensize: 100vw;
}

@keyframes rotate {
  0% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(10px);
    opacity: 1;
  }
  50% {
    transform: translateY(-10px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(10px);
    opacity: 1;
  }
}

.sponsor-us {
  &-title {
    text-align: left;
  }

  &-content {
    display: flex;
    flex-direction: row;
    @include phone {
      flex-direction: column;
    }
  }

  &-text {
    text-align: left;
  }

  &-button-container {
    margin-top: 30px;
    height: auto;
    display: flex;
    gap: 30px;
    @include tablet {
      flex-direction: column;
    }
  }

  &-description {
    margin-bottom: 8px;
    p {
      margin-bottom: 16px;
    }
    @include phone {
      width: 100%;
      p {
        margin-bottom: 8px;
      }
    }
  }

  &-image {
    width: 30%;
  }

  h2 {
    margin-top: 1rem;
    @include phone {
      margin-bottom: 2rem;
    }
  }

  &-carousel {
    padding-top: 50px;
    display: block;
    width: 100vw;
    left: 0;
    position: absolute;
    padding-bottom: 50px;
  }

  &-carousel-wrapper {
    @media (min-width: 800px){
      padding-bottom: 700px;
    }
    @media (max-width: 800px) {
      padding-bottom: 800px;
    }
  }
}

#sponsor {
  overflow: hidden;
}
