@import "../../constants";

.keynote {
  &-title {
    text-align: left;
  }

  &-header {
    display: flex;
    background-color: #a8efe4;
    border-bottom: 3px solid;
    height: 6em;
    &-name {
      margin: auto;
      font-size: 40px;
      font-weight: 700;
      font-family: "Quicksand";
    }
    @include phone {
      &-name {
        margin: auto;
        font-size: 26px;
        font-weight: 600;
        font-family: "Quicksand";
      }
    }
  }

  &-box {
    border-style: solid;
    border-width: 3px;
    box-shadow: 20px 20px #19123c;
  }

  &-card {
    background-color: #ffffff;
    text-align: left;
    display: grid;
    grid-template-areas:
      "card-img card-title"
      "card-img card-desc";
    grid-template-columns: 35% 65%;
    grid-template-rows: 30% 70%;
    justify-content: center;
    column-gap: 2em;
    align-items: center;
    padding: 10%;

    @media (max-width: 800px) {
      grid-template-areas:
        "card-img"
        "card-title"
        "card-desc";
      grid-template-columns: 100%;
      grid-template-rows: 40% 15% 35%;
      row-gap: 20px;

      &-img {
        margin: auto;
      }
      &-title {
        text-align: center;

        &-box {
          margin: auto;
        }
      }
      .keynote-card-img {
        box-shadow: none !important;
      }
    }

    @media (max-width: 450px) {
      grid-template-rows: 25% 10% 50%;
      &-name {
        font-size: 25px !important;
        line-height: 1.5;
      }

      &-role {
        text-align: left;
      }
    }

    @media (max-width: 350px) {
      padding: 1.5em;
    }

    &-img {
      grid-area: card-img;
      max-width: 90%;
      max-height: 100%;
      border: 3px solid;
      margin: auto;
      border-radius: 10px;
      transition: transform 0.3s linear;
    }

    &-title-box {
      background-color: white;
      border: 2px solid;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column-gap: 20px;
      padding: 8px;
      max-width: 400px;
      margin-bottom: 30px;
    }

    &-star {
      grid-area: card-title;
    }
    &-name {
      grid-area: card-title;
      font-size: 48px;
      padding: 20px 0px;
      font-weight: 800;
      font-family: "Quicksand";
      @include phone {
        font-size: 30px;
      }
    }
    &-role {
      grid-area: card-title;
    }

    &-desc {
      grid-area: card-desc;
    }

    &-desc-wrapper {
      height: 100%;
      display: flex;
      align-items: flex-start;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &:hover {
      .keynote-card-img {
        box-shadow: 15px 15px #a8efe4;
        transform: translateY(-10px);
        transition: transform 0.3s linear;
      }
    }
  }
}
