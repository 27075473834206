@import "../../constants";

.team {
    &-container {
        padding-bottom: 30px;
        min-height: 100vh;
        h2 {
            margin-top: 30px;
        }
    }

    &-title {
        text-align: left;
    }

    &-text {
        // padding: 2px 0 10px 0;
        @include phone {
            top: 60px;
            align-self: flex-start;
            width: 100%;
        }
        &-default {
            display: block;
            @include phone {
                display: none;
            }
        }
        &-mobile {
            display: none;
            @include phone {
                display: block;
            }
        }
        div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                text-align: left;
                margin-left: 10px;
            }
            img {
                width: 32px;
                @include phone {
                    width: 48px;
                }
            }
        }
    }

    &-folders {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 20px;
        margin: 20px;

        @media (max-width: 768px) {
            margin: 10px;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &-folder {
        height: 200px;
        img {
            width: 180px;
        }
        cursor: pointer;

        &-img-container {
            min-height: 155px;
        }
    }

    &-members {
        display: grid;
        grid-template-columns: repeat(7, minmax(130px, 1fr));
        @include desktop {
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
        }
        gap: 20px;
        @include phone {
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }
    }

    &-member-card {
        display: grid;
        justify-items: center;
        row-gap: 5px;
    }

    &-img-container {
        width: 120px;
        height: 120px;
        :hover {
            transform: scale(0.95);
        }
        img {
            align-self: flex-start;
            height: 100%;
            width: auto;
        }
        @include phone {
            width: 90px;
            height: 90px;
        }
    }

    &-icon-card {
        display: flex;
        justify-content: center;        
        position: relative;
    }


    &-icon-container {
        width: 130px;
        height: 130px;
        position: absolute;

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            height: 95%;
            width: auto;
        }

        p {
            margin-top: 10px;
            font-size: 18px;
        }

        @include phone {
            width: 90px;
            height: 90px;
        }
    }

    &-carousel {
        display: flex;
        align-items: center;
        margin: 50px 0px;
        @include tablet {
            margin: 25px 0px;
        }
    }

    &-pagination {
        width: 30px;
        cursor: pointer;
        &.prev {
            img {
                transform: rotate(180deg);
            }
        }
        img {
            max-width: 100%;
        }
    }
}