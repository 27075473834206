@import "../../constants";

.about {
  &-container {
    text-align: left;
    display: flex;
    flex-direction: column;

    padding-bottom: 40px;
  }

  &-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 40%;
    @include phone {
      flex-direction: column;
    }
  }

  &-divider {
    border: 1px solid;
    width: 100%;
    margin-left: 0px;
  }

  &-text {
    padding: 20px, 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: $font-regular;
    font-size: $text-medium;
    p {
      margin-top: 15px;
    }
    @include phone {
      width: 100%;
      p {
        margin-top: 10px;
      }
    }
  }

  &-events {
    margin-top: 100px;
    height: 400px;
    display: grid !important;
    text-align: center;
    grid-template-columns: 50% 50%;
    align-items: center;

    img {
      max-width: 100%;
    }

    &-image {
      max-width: 300px !important;
    }

    &-title {
      text-align: left;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  &-slider {
    width: min(80vw, 1200px);
  }

}
