@import "../../constants";

.button {
  padding: 13px;
  display: inline-flex;
  align-items: center;
  height: auto;
  gap: 10px;
  background-color: $accent-purple;
  color: $text-light;

  font-size: 16px;
  font-family: "Poppins";
  font-weight: 700;
  text-decoration: none;
  border-radius: 12px;
  white-space: nowrap;
  box-shadow: 10px 10px 0px 0px #19123c;
  @include phone {
    font-size: 14px;
  }

  &.pink{
    background-color: $accent-pink;

    &:hover {
      background-color: $accent-pink-focus;
    }
  }

  &:hover {
    background-color: $accent-purple-focus;
    opacity: 1;
  }

  &:active {
    box-shadow: none;
    border-width: 2px;
    border-color: #19123c;
  }

  img {
    max-width: 20px;
    max-height: 20px;
  }

  .label {
    margin-right: auto;
  }

}
