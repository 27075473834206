@import "../../constants";

.landing {
  &-container {
    height: 85vh;
    margin-top: 7vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include phone {
      flex-direction: column;
      justify-content: center;
    }
  }
  &-main-container {
    display: flex;
    flex-direction: row;

    column-gap: 6vw;
    @include tablet {
      max-width: 50vw;
    }

    @include phone {
      max-width: 70vw;
      align-items: center;
    }
  }

  &-text {
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    font-family: $font-regular;
    width: 100%;
    text-align: left;
    row-gap: 12px;
    @include phone {
      align-items: center;
      text-align: center;
      row-gap: 10px;
    }
  }

  &-mlh {
    display: none;

    @include phone {
      display: block;
      height: 28px;
    }
  }

  &-title {
    font-size: $text-xxlarge;
    font-weight: 600;
    font-family: $font-logo;
    @include tablet {
      font-size: $text-large;
    }
    @include phone {
      font-size: $text-medium;
    }
  }
  &-logo-text {
    display: flex;
    gap: 20px;

    img {
      height: 45px;
    }
    @include tablet {
      img {
        height: 40px;
      }
    }
    @include phone {
      .landing-logo {
        display: none;
      }
      img {
        height: 30px;
      }
    }
  }
  &-tech-together-container {
    display: flex;
    gap: 20px;
    margin: 0px 43px 0px auto;
    img {
      height: 50px;
      width: 190px;
    }
    h4 {
      margin: auto 0px;
    }
    @include phone {
      margin: auto;
      gap: 5px;
      h4 {
        font-size: $text-xsmall;
      }
      img {
        height: 44px;
        width: 135px;
      }
    }
  }

  &-statement {
    font-family: "Quicksand";
    font-size: $text-xlarge;
    font-weight: 400;
    @include tablet {
      font-size: $text-small;
    }
    @include phone {
      font-size: $text-xsmall;
      width: 270px;
      line-height: normal;
    }
  }

  &-description {
    font-size: $text-medium;
    font-weight: 400;
    @include tablet {
      font-size: $text-small;
    }
    @include phone {
      font-size: $text-xsmall;
      width: 270px;
      line-height: normal;
    }
  }

  &-date {
    grid-area: text-dates;
    font-family: "Lexend";
    font-weight: 600;
    font-size: $text-medium;
    @include phone {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &-button-container {
    margin-top: 30px;
    height: auto;
    display: flex;
    gap: 30px;
    @include tablet {
      flex-direction: column;
    }
  }

  &-graphic-container {
    z-index: -100;
    #snakes-and-latters {
      width: 30em;
      @include desktop {
        width: 15em;
      }
    }

    @include phone {
      display: none;
    }
  }
}

#teal-asterisk {
  position: absolute;
  z-index: -100;
  left: 0;
  bottom: -30%;

  @include phone {
    width: 17em;
    bottom: -30%;
  }
}
