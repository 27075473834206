@import "../../constants";

.our-sponsors-container {
  padding-top: 60px;

  @include phone {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.carousel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .carousel-slide {
    height: 200px;
    width: 35%;
    border-style: solid;
    border-radius: 20px;
    border-width: 3px;
    box-shadow: 15px 15px $background-dark;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 30px; 
    object-fit: contain;

    &-text {
      width: 40%;
      margin-left: 90px;
      text-align: left;

      @include phone {
        width: 100%;
        text-align: center;
        margin-left: 0;
      }
    }
  
    &-img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      display: block;
    }

    @include phone {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  @include phone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

svg {
  width: 14px;
  height: 24px;
  overflow: visible;
  cursor: pointer;
  padding-top: 3px;
  fill:#ADB4F9;

  &:hover {
    fill: $accent-purple-focus;
  }
}

.carousel-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  .carousel-dots-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .carousel-dot {
      width: 10px;
      height: 10px;
      background-color: #ADB4F9;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    
      &.active {
        background-color: $accent-purple-focus;
      }
    }
  }

  @include phone {
    margin-top: 25px;
  }
}
