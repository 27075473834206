@import "../../constants";

.footerbar {
    &-title {
        font-family: $font-logo;
        font-size: 18px;
        font-weight: 600;
        @include phone {
            font-size: 16px;
        }
    }
    &-container {
        height: 60px;
        background-color: $primary-purple;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            padding-top: 5px;
            font-size: 14px;
            @include phone {
                font-size: 16px;
            }
        }
        a {
            margin-top: 5px;
            font-size: 14px;
        }
        @include phone {
            height: 55px;
        }
    }
}